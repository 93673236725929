import { extendTheme } from '@chakra-ui/react';
import { PRIMARY_FONT, SECONDARY_FONT, ALTERNATIVE_FONT } from '@/constants/fonts';

import {
  BG_SECONDARY,
  BG_PRIMARY,
  BG_SECONDARY_DARK,
  BG_SECONDARY_TRANSPARENT,
  TEXT_PRIMARY,
  TEXT_PRIMARY_LIGHT,
  TEXT_SECONDARY,
} from '@/constants/colors';

/**
 * override chackra-ui for personalize purpose
 * see the docs: https://chakra-ui.com/guides/using-fonts
 */
const theme = {
  fonts: {
    heading: PRIMARY_FONT,
    body: SECONDARY_FONT,
    fantasy: ALTERNATIVE_FONT,
    jost: 'Jost, serif;',
  },
  colors: {
    mainColor: BG_SECONDARY,
    mainColorText: TEXT_PRIMARY,
    mainColorTextLight: TEXT_PRIMARY_LIGHT,
    secondaryColorText: TEXT_SECONDARY,
    transparent: 'transparent',
    bgSecondary: BG_SECONDARY,
    theme: {
      bgPrimaryText: TEXT_PRIMARY,
      bgPrimary: BG_PRIMARY,
      bgSecondary: BG_SECONDARY,
      bgSecondaryDark: BG_SECONDARY_DARK,
      bgSecondaryTransparent: BG_SECONDARY_TRANSPARENT,
    },
    button: {
      secondary: {
        500: BG_SECONDARY,
      },
      secondaryDark: {
        500: BG_SECONDARY_DARK,
      },
    },
  },
  shadows: {
    outline: BG_SECONDARY_DARK,
  },
};

export default extendTheme(theme);
